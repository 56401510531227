import React from 'react'
import { Layout } from 'antd'
import SiderComponent from './SiderComponent'
import HeaderComponent from './HeaderComponent'
import ContentComponent from './ContentComponent'
import { IStateType } from '../../../model/app-state.model'

const { Footer } = Layout

export interface INavProps {
  userDetails: IStateType
  logout: () => void
}

const LayoutComponent = ({
  userDetails,
  logout,
}: INavProps): React.ReactElement => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SiderComponent userDetails={userDetails} />
      <Layout>
        <HeaderComponent userDetails={userDetails} logout={logout}/>
        <ContentComponent />
        <Footer style={{ textAlign: 'center' }}>
          One finance ©{new Date().getFullYear()} Created by Thitech
        </Footer>
      </Layout>
    </Layout>
  )
}

export default LayoutComponent