import { IAuditable } from "../../../model/auditable.model";
import { IError } from "../../../model/error.model";
import { IPageableResponse } from "../../../model/page.model";
import { IConsumableResponse } from "../../MasterData/Consumable/constants";
import { ICustomerResponse } from "../../MasterData/Customer/constants";
import { IEmployeeResponse } from "../../MasterData/Employee/constants";
import { IProductResponse } from "../../MasterData/Product/constants";
import { Organization } from "../../UsersAndRoles/constants";

export interface IQuoteResponse extends IAuditable {
  id: number;
  customer: ICustomerResponse;
  organization: Organization;
  employee: IEmployeeResponse;
  createdEmployee: IEmployeeResponse;
  version: number;
  notes: string;
  title: string;
  mispahScope: string;
  clientScope: string;
  generalTermsAndConditions: string;
  invoicingAndPaymentTerms: string;
  preparedBy: string;
  jobLocation: string;
  rfqNumber: string;
  status: QuoteStatus;
  quoteItems: IQuoteItem[];
  quoteItemConsumables: IQuoteItemConsumable[];
  code: string;
}

export interface IQuoteItem extends IAuditable {
  id: string;
  product: IProductResponse;
  quantity: number;
  pricePerUnit: number;
  version: number;
  isActive: boolean;
  remarks?: string;
}

export interface IQuoteItemConsumable extends IAuditable {
  id: string;
  consumable: IConsumableResponse;
  quantity: number;
  pricePerUnit: number;
  version: number;
  isActive: boolean;
  remarks?: string;
}

export enum QuoteStatus {
  DRAFT = "DRAFT",
  REVIEW = "REVIEW",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
}
export interface QuoteListState {
  isLoading: boolean;
  data: QuoteListPageable | null;
  error: IError | null;
}

export interface QuoteListPageable extends IPageableResponse {
  content: IQuoteResponse[];
}

export interface IQuoteRequest {
  id: string;
}

export interface QuoteState {
  isLoading: boolean;
  data: IQuoteResponse | null;
  error: IError | null;
}

export enum QuoteUrl {
  LIST = "/quotes",
  BY_ID = "/quotes/:quoteId",
}
